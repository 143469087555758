import React, {useState} from "react";
import {Popover, PopoverBody} from "reactstrap";

const BoardList = ({
                       board,
                       unique,
                       actiononContact,
                       deleteBoardHandler, edit=true
                   }) => {
    const [optionMenu, setOptionMenu] = useState(false);

    const activeEdit = e => {
        setOptionMenu(!optionMenu);
    };

    return (
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-15">
            <div className="whitelight pa-24 cursor-pointer with-transition roe-box-shadow pos-relative board-grid">
                <div className="more_icon board-more-option">
                    <button
                        id={`board-${unique}-${board.id}`}
                        onClick={e => e.stopPropagation()}
                        className="transparent-button"
                    >
                        <i className="fas fa-ellipsis-h"/>
                    </button>
                    <Popover
                        trigger="legacy"
                        className="roy-menu"
                        innerClassName="roy-inner-content"
                        placement="bottom-end"
                        target={`board-${unique}-${board.id}`}
                        isOpen={optionMenu}
                        toggle={() => setOptionMenu(!optionMenu)}
                    >
                        {edit && <PopoverBody>
                            <div
                                className="roy-menu-list"
                                onClick={() => {
                                    activeEdit();
                                    actiononContact("edit", board);
                                }}
                            >
                                Modifier
                            </div>
                            <div
                                className="roy-menu-list"
                                onClick={e => {
                                    e.stopPropagation();
                                    deleteBoardHandler(board.id);
                                }}
                            >
                                Supprimer
                            </div>
                        </PopoverBody>}
                    </Popover>
                </div>
                <div className="board">
                    <div className="overlay flex-x center">
                        <div className="text-center">
                            <div
                                className="fs-20 bold-text px15 board-list-title"
                                style={{
                                    wordBreak: "break-all",
                                    padding: "0px 15px"
                                }}
                            >
                                <div>
                                    <i
                                        className="icon fas fa-address-card"
                                        style={{fontSize: 45}}
                                    />
                                </div>
                                <div style={{color: "#d61224", fontSize: 16}}>
                                    {board.firstName} {board.lastName}
                                </div>
                                <div style={{color: "rgb(187, 144, 148)", fontSize: 11}}>
                                    {board.contactInformation !== undefined
                                        ? board.contactInformation.email
                                        : ""}
                                </div>
                                <div style={{color: "rgb(187, 144, 148)"}}>
                                    {board.name !== undefined ? board.name : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BoardList;
