import React, {useEffect, useState} from "react";
import {
    FormGroup
} from "reactstrap";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaButton} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import BoardList from "../../../components/scrumboard/BoardList";
import ScrumboardWrapper from "../../../components/scrumboard/scrumboard.style";
import scrumActions from "../../../redux/scrumboard/actions";
import {connect} from "react-redux";
import ContactForm from "../../../components/scrumboard/ContactForm";
import {contact, identity} from "../../../helpers/constants";

const context = "contacts";
const {updateBoards} = scrumActions;

const EmployeeContacts = props => {

    const {register, handleSubmit, errors, setValue, reset} = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [listContacts, setListContacts] = React.useState([]);

    const [currentModelAction, setCurrentModelAction] = useState("add");
    const [editedContent, setEditedContent] = useState({});
    const [isAddBoard, setIsAddBoard] = useState(false);
    const [contactModel, setContactModel] = useState(false);

    const onSubmit = data => {
        console.log("on submit data", data);
        console.log("on submit add contact", listContacts);
        let contactObj = {
            code:new Date(),
            civility: data.civility,
            enable: true,
            firstName: data.firstName,
            lastName: data.lastName,
            contactInformation: {
                phone: data.phone,
                mobile: data.mobile,
                email: data.email,
                fax: data.fax
            }
        };
        if(data.id !== undefined){
            setListContacts(replaceElementInArray(listContacts, contactObj, data.id));
        }else{
            setListContacts(listContacts.concat(contactObj));
        }
        setContactModel(false);
        reset();
    };

    const replaceElementInArray = (oldList, object, index) => {
        let newList = Object.assign([], oldList, {[index]: object});
        return newList;
    };

    const nextStep = () => {
        props.saveEmployee({
            emergencyContacts: listContacts
        });
    };

    useEffect(() => {
    }, [listContacts]);

    const deleteElementFromList = (key, list) => {
        console.log("key", key);
        let arr = [];
        list.forEach((value, keyLoop) => {
            if (key !== keyLoop) {
                arr.push(value);
            }
        });
        return arr;
    };

    const actiononContact = (action, object) => {
        console.log("e", object);
        if (action === "add") {
            setCurrentModelAction("add");
            setEditedContent({});
        } else if (action === "edit") {
            setCurrentModelAction("edit");
            setEditedContent(object);
        }
        setContactModel(true);
    };

    const closeForm = () => {
        setCurrentModelAction("");
        setEditedContent({});
        setContactModel(false);
    };


    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style col-sm-12 row">
                            <div className="roe-card-body col-sm-3">
                                <div className="pl-5 pt-4 aligner-wrapper text-center">
                                    <img className="pt-5"
                                         src={contact}
                                         style={{width:"200px"}}
                                    />
                                    <div style={{marginTop:"10px"}}>
                                        <h3 className="card-title fs-25 bold-text">Contacts</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="roe-card-body col-sm-9">
                                <ScrumboardWrapper {...props}>
                                    <div>
                                        <div className="pt-10">
                                            <div className="fs-20 bold-text ml-15 pb-15 board-type-head">
                                                Liste de contacts
                                            </div>
                                            <div className="row ma-0">
                                                {listContacts.map((board, i) => {
                                                    return (
                                                        <BoardList
                                                            key={i}
                                                            unique="personal"
                                                            board={{id: i, ...board}}
                                                            actiononContact={() => {
                                                                setEditedContent({id: i, ...board});
                                                                actiononContact("edit", {id: i, ...board});
                                                            }}
                                                            deleteBoardHandler={() =>
                                                                setListContacts(
                                                                    deleteElementFromList(i, listContacts)
                                                                )
                                                            }
                                                        />
                                                    );
                                                })}


                                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-15 ">
                                                    <div
                                                        className="whitelight pa-24 cursor-pointer with-transition roe-box-shadow">
                                                        <div className="board">
                                                            <div
                                                                className="overlay flex-x center"
                                                                onClick={() => actiononContact("add", null)}
                                                            >
                                                                <div>
                                                                    <div className="text-center">
                                                                        {isAddBoard ? (
                                                                            <div/>
                                                                        ) : (
                                                                            <div
                                                                                className="fs-20 bold-text board-list-title">
                                                                                <i className="fas fa-plus"/>{" "}
                                                                                <IntlMessages
                                                                                    id="crm.common.create.contact"/>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ScrumboardWrapper>
                                {contactModel && (
                                    <ContactForm
                                        currentModelAction={currentModelAction}
                                        editObject={editedContent}
                                        addNewItem={onSubmit}
                                        dispatch={props.dispatch}
                                        selectedObjects={props.selectedObjects}
                                        close={closeForm}
                                        showContactModal={contactModel}
                                    />
                                )}

                                <FormGroup>
                                    <AdaButton
                                        onClick={nextStep}
                                        className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2"
                                    >
                                        <IntlMessages id="action.create.employee"/>
                                    </AdaButton>

                                    <AdaButton onClick={() => props.step("3")}
                                               className="no-border c-outline-AdaBack c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                                        <IntlMessages id="action.precedent.contract"/>
                                    </AdaButton>

                                    <AdaButton
                                        className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                        onClick={props.cancelAction}
                                    >
                                        <IntlMessages id="action.common.cancel"/>
                                    </AdaButton>


                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger,
        boards: state.scrumboard.boards
    };
};

export default connect(
    mapStateToProps,
    {
        updateBoards
    }
)(EmployeeContacts);
