import IntlMessages from "../../../helpers/intlMessages";
import React from "react";

export const  familySituation = [
    {
        key: 1,
        label: "marié",
        value: "marié"
    },
    {
        key: 2,
        label: "pacsé",
        value: "pacsé"
    },
    {
        key: 3,
        label: "divorcé",
        value: "divorcé"
    },
    {
        key: 4,
        label: "séparé",
        value: "séparé"
    },
    {
        key: 5,
        label: "célibataire",
        value: "célibataire"
    },
    {
        key: 6,
        label: "veuf",
        value: "veuf"
    }
]