import IntlMessages from "../../../helpers/intlMessages";
import React from "react";

export const country = [
  {
    key: 1,
    label: "afghane",
    value: "Afghanistan"
  },
  {
    key: 2,
    label: "sudafricaine",
    value: "Afrique du Sud"
  },
  {
    key: 3,
    label: "albanaise",
    value: "Albanie"
  },
  {
    key: 4,
    label: "algérienne",
    value: "Algérie"
  },
  {
    key: 5,
    label: "allemande",
    value: "Allemagne"
  },
  {
    key: 6,
    label: "andorrane",
    value: "Andorre"
  },
  {
    key: 7,
    label: "angolaise",
    value: "Angola"
  },
  {
    key: 8,
    label: "antiguayenne",
    value: "Antigua-et-Barbuda"
  },
  {
    key: 9,
    label: "saoudienne",
    value: "Arabie saoudite"
  },
  {
    key: 10,
    label: "argentine",
    value: "Argentine"
  },
  {
    key: 11,
    label: "arménienne",
    value: "Arménie"
  },
  {
    key: 12,
    label: "australienne",
    value: "Australie"
  },
  {
    key: 13,
    label: "autrichienne",
    value: "Autriche"
  },
  {
    key: 14,
    label: "azerbaïdjanaise",
    value: "Azerbaïdjan"
  },
  {
    key: 15,
    label: "bahaméenne",
    value: "Bahamas"
  },
  {
    key: 16,
    label: "bahreïnienne",
    value: "Bahreïn"
  },
  {
    key: 17,
    label: "bangladaise",
    value: "Bangladesh"
  },
  {
    key: 18,
    label: "barbadienne",
    value: "Barbade"
  },
  {
    key: 19,
    label: "belge",
    value: "Belgique"
  },
  {
    key: 20,
    label: "bélizienne",
    value: "Belize"
  },
  {
    key: 22,
    label: "bhoutanaise",
    value: "Bhoutan"
  },
  {
    key: 24,
    label: "birmane",
    value: "Birmanie"
  },
  {
    key: 23,
    label: "biélorusse",
    value: "Biélorussie"
  },
  {
    key: 25,
    label: "bolivienne",
    value: "Bolivie"
  },
  {
    key: 26,
    label: "bosnienne",
    value: "Bosnie-Herzégovine"
  },
  {
    key: 27,
    label: "botswanaise",
    value: "Botswana"
  },
  {
    key: 29,
    label: "brunéienne",
    value: "Brunei"
  },
  {
    key: 28,
    label: "brésilienne",
    value: "Brésil"
  },
  {
    key: 30,
    label: "bulgare",
    value: "Bulgarie"
  },
  {
    key: 31,
    label: "burkinabé",
    value: "Burkina"
  },
  {
    key: 32,
    label: "burundaise",
    value: "Burundi"
  },
  {
    key: 21,
    label: "béninoise",
    value: "Bénin"
  },
  {
    key: 33,
    label: "cambodgienne",
    value: "Cambodge"
  },
  {
    key: 34,
    label: "camerounaise",
    value: "Cameroun"
  },
  {
    key: 35,
    label: "canadienne",
    value: "Canada"
  },
  {
    key: 36,
    label: "cap-verdienne",
    value: "Cap-Vert"
  },
  {
    key: 144,
    label: "centrafricaine",
    value: "Centrafrique"
  },
  {
    key: 37,
    label: "chilienne",
    value: "Chili"
  },
  {
    key: 38,
    label: "chinoise",
    value: "Chine"
  },
  {
    key: 39,
    label: "chypriote",
    value: "Chypre"
  },
  {
    key: 40,
    label: "colombienne",
    value: "Colombie"
  },
  {
    key: 41,
    label: "comorienne",
    value: "Comores"
  },
  {
    key: 42,
    label: "congolaise",
    value: "Congo"
  },
  {
    key: 43,
    label: "nord-coréenne",
    value: "Corée du Nord"
  },
  {
    key: 44,
    label: "sud-coréenne",
    value: "Corée du Sud"
  },
  {
    key: 45,
    label: "costaricienne",
    value: "Costa Rica"
  },
  {
    key: 47,
    label: "croate",
    value: "Croatie"
  },
  {
    key: 48,
    label: "cubaine",
    value: "Cuba"
  },
  {
    key: 46,
    label: "ivoirienne",
    value: "Côte d'Ivoire"
  },
  {
    key: 49,
    label: "danoise",
    value: "Danemark"
  },
  {
    key: 50,
    label: "djiboutienne",
    value: "Djibouti"
  },
  {
    key: 51,
    label: "dominiquaise",
    value: "Dominique"
  },
  {
    key: 52,
    label: "egyptienne",
    value: "Egypte"
  },
  {
    key: 53,
    label: "emirienne",
    value: "Emirats arabes unis"
  },
  {
    key: 54,
    label: "equatorienne",
    value: "Equateur"
  },
  {
    key: 55,
    label: "erythréenne",
    value: "Erythrée"
  },
  {
    key: 56,
    label: "espagnole",
    value: "Espagne"
  },
  {
    key: 57,
    label: "estonienne",
    value: "Estonie"
  },
  {
    key: 58,
    label: "américaine",
    value: "Etats-Unis"
  },
  {
    key: 59,
    label: "ethiopienne",
    value: "Ethiopie"
  },
  {
    key: 60,
    label: "fidjienne",
    value: "Fidji"
  },
  {
    key: 61,
    label: "finlandaise",
    value: "Finlande"
  },
  {
    key: 62,
    label: "française",
    value: "France"
  },
  {
    key: 63,
    label: "gabonaise",
    value: "Gabon"
  },
  {
    key: 64,
    label: "gambienne",
    value: "Gambie"
  },
  {
    key: 66,
    label: "ghanéenne",
    value: "Ghana"
  },
  {
    key: 68,
    label: "grenadienne",
    value: "Grenade"
  },
  {
    key: 67,
    label: "grecque",
    value: "Grèce"
  },
  {
    key: 69,
    label: "guatémaltèque",
    value: "Guatemala"
  },
  {
    key: 70,
    label: "guinéenne",
    value: "Guinée"
  },
  {
    key: 71,
    label: "equatoguinéenne",
    value: "Guinée équatoriale"
  },
  {
    key: 72,
    label: "bissaoguinéenne",
    value: "Guinée-Bissao"
  },
  {
    key: 73,
    label: "guyanienne",
    value: "Guyana"
  },
  {
    key: 65,
    label: "géorgienne",
    value: "Géorgie"
  },
  {
    key: 74,
    label: "haïtienne",
    value: "Haïti"
  },
  {
    key: 75,
    label: "hondurienne",
    value: "Honduras"
  },
  {
    key: 77,
    label: "hongroise",
    value: "Hongrie"
  },
  {
    key: 79,
    label: "indienne",
    value: "Inde"
  },
  {
    key: 80,
    label: "indonésienne",
    value: "Indonésie"
  },
  {
    key: 81,
    label: "irakienne",
    value: "Irak"
  },
  {
    key: 82,
    label: "iranienne",
    value: "Iran"
  },
  {
    key: 83,
    label: "irlandaise",
    value: "Irlande"
  },
  {
    key: 84,
    label: "islandaise",
    value: "Islande"
  },
  {
    key: 85,
    label: "israélienne",
    value: "Israël"
  },
  {
    key: 86,
    label: "italienne",
    value: "Italie"
  },
  {
    key: 87,
    label: "jamaïquaine",
    value: "Jamaïque"
  },
  {
    key: 88,
    label: "japonaise",
    value: "Japon"
  },
  {
    key: 90,
    label: "jordanienne",
    value: "Jordanie"
  },
  {
    key: 89,
    label: "palestinienne",
    value: "Jérusalem - Territoires palestiniens"
  },
  {
    key: 91,
    label: "kazakhstanais",
    value: "Kazakhstan"
  },
  {
    key: 92,
    label: "kényane",
    value: "Kenya"
  },
  {
    key: 93,
    label: "kirghize",
    value: "Kirghizstan"
  },
  {
    key: 94,
    label: "kiribatienne",
    value: "Kiribati"
  },
  {
    key: 198,
    label: "kosovare",
    value: "Kosovo"
  },
  {
    key: 95,
    label: "koweïtienne",
    value: "Koweït"
  },
  {
    key: 96,
    label: "laotienne",
    value: "Laos"
  },
  {
    key: 97,
    label: "lesothienne",
    value: "Lesotho"
  },
  {
    key: 98,
    label: "lettone",
    value: "Lettonie"
  },
  {
    key: 99,
    label: "libanaise",
    value: "Liban"
  },
  {
    key: 100,
    label: "libérienne",
    value: "Liberia"
  },
  {
    key: 101,
    label: "libyenne",
    value: "Libye"
  },
  {
    key: 102,
    label: "liechtensteinoise",
    value: "Liechtenstein"
  },
  {
    key: 103,
    label: "lituanienne",
    value: "Lituanie"
  },
  {
    key: 104,
    label: "luxembourgeoise",
    value: "Luxembourg"
  },
  {
    key: 105,
    label: "macédonienne",
    value: "Macédoine"
  },
  {
    key: 106,
    label: "malgache",
    value: "Madagascar"
  },
  {
    key: 107,
    label: "malaisienne",
    value: "Malaisie"
  },
  {
    key: 108,
    label: "malawienne",
    value: "Malawi"
  },
  {
    key: 109,
    label: "maldivienne",
    value: "Maldives"
  },
  {
    key: 110,
    label: "malienne",
    value: "Mali"
  },
  {
    key: 111,
    label: "maltaise",
    value: "Malte"
  },
  {
    key: 112,
    label: "marocaine",
    value: "Maroc"
  },
  {
    key: 113,
    label: "marshallaise",
    value: "Marshall"
  },
  {
    key: 114,
    label: "mauricienne",
    value: "Maurice"
  },
  {
    key: 115,
    label: "mauritanienne",
    value: "Mauritanie"
  },
  {
    key: 116,
    label: "mexicaine",
    value: "Mexique"
  },
  {
    key: 117,
    label: "micronésienne",
    value: "Micronésie"
  },
  {
    key: 118,
    label: "moldave",
    value: "Moldavie"
  },
  {
    key: 119,
    label: "monégasque",
    value: "Monaco"
  },
  {
    key: 120,
    label: "mongole",
    value: "Mongolie"
  },
  {
    key: 197,
    label: "monténégrine",
    value: "Monténégro"
  },
  {
    key: 121,
    label: "mozambicaine",
    value: "Mozambique"
  },
  {
    key: 122,
    label: "namibienne",
    value: "Namibie"
  },
  {
    key: 123,
    label: "nauruane",
    value: "Nauru"
  },
  {
    key: 125,
    label: "nicaraguayenne",
    value: "Nicaragua"
  },
  {
    key: 126,
    label: "nigérienne",
    value: "Niger"
  },
  {
    key: 127,
    label: "nigériane",
    value: "Nigeria"
  },
  {
    key: 128,
    label: "norvégienne",
    value: "Norvège"
  },
  {
    key: 129,
    label: "néo-zélandaise",
    value: "Nouvelle-Zélande"
  },
  {
    key: 124,
    label: "népalaise",
    value: "Népal"
  },
  {
    key: 130,
    label: "omanaise",
    value: "Oman"
  },
  {
    key: 131,
    label: "ougandaise",
    value: "Ouganda"
  },
  {
    key: 132,
    label: "ouzbèke",
    value: "Ouzbékistan"
  },
  {
    key: 133,
    label: "pakistanaise",
    value: "Pakistan"
  },
  {
    key: 134,
    label: "palaoise",
    value: "Palaos"
  },
  {
    key: 135,
    label: "panaméenne",
    value: "Panama"
  },
  {
    key: 136,
    label: "papouasienne",
    value: "Papouasie-Nouvelle-Guinée"
  },
  {
    key: 137,
    label: "paraguayenne",
    value: "Paraguay"
  },
  {
    key: 138,
    label: "néerlandaise",
    value: "Pays-Bas"
  },
  {
    key: 140,
    label: "philippine",
    value: "Philippines"
  },
  {
    key: 141,
    label: "polonaise",
    value: "Pologne"
  },
  {
    key: 142,
    label: "portugaise",
    value: "Portugal"
  },
  {
    key: 139,
    label: "péruvienne",
    value: "Pérou"
  },
  {
    key: 143,
    label: "qatarienne",
    value: "Qatar"
  },
  {
    key: 148,
    label: "roumaine",
    value: "Roumanie"
  },
  {
    key: 149,
    label: "britannique",
    value: "Royaume-Uni"
  },
  {
    key: 150,
    label: "russe",
    value: "Russie"
  },
  {
    key: 151,
    label: "rwandaise",
    value: "Rwanda"
  },
  {
    key: 146,
    label: "dominicaine",
    value: "République dominicaine"
  },
  {
    key: 145,
    label: "congolaise (RDC)",
    value: "République démocratique du Congo"
  },
  {
    key: 147,
    label: "tchèque",
    value: "République tchèque"
  },
  {
    key: 152,
    label: "christophienne",
    value: "Saint-Christophe-et-Niévès"
  },
  {
    key: 154,
    label: "marinaise",
    value: "Saint-Marin"
  },
  {
    key: 155,
    label: null,
    value: "Saint-Siège"
  },
  {
    key: 156,
    label: "vincentaise",
    value: "Saint-Vincent-et-les Grenadines"
  },
  {
    key: 153,
    label: "lucienne",
    value: "Sainte-Lucie"
  },
  {
    key: 157,
    label: "salomonaise",
    value: "Salomon"
  },
  {
    key: 158,
    label: "salvadorienne",
    value: "Salvador"
  },
  {
    key: 159,
    label: "samoene",
    value: "Samoa"
  },
  {
    key: 160,
    label: "santoméenne",
    value: "Sao Tomé-et-Principe"
  },
  {
    key: 162,
    label: "serbe",
    value: "Serbie"
  },
  {
    key: 163,
    label: "seychelloise",
    value: "Seychelles"
  },
  {
    key: 164,
    label: "sierraléonaise",
    value: "Sierra Leone"
  },
  {
    key: 165,
    label: "singapourienne",
    value: "Singapour"
  },
  {
    key: 166,
    label: "slovaque",
    value: "Slovaquie"
  },
  {
    key: 167,
    label: "slovène",
    value: "Slovénie"
  },
  {
    key: 168,
    label: "somalienne",
    value: "Somalie"
  },
  {
    key: 169,
    label: "soudanaise",
    value: "Soudan"
  },
  {
    key: 170,
    label: "srilankaise",
    value: "Sri Lanka"
  },
  {
    key: 172,
    label: "suisse",
    value: "Suisse"
  },
  {
    key: 173,
    label: "surinamaise",
    value: "Suriname"
  },
  {
    key: 171,
    label: "suédoise",
    value: "Suède"
  },
  {
    key: 174,
    label: "swazie",
    value: "Swaziland"
  },
  {
    key: 175,
    label: "syrienne",
    value: "Syrie"
  },
  {
    key: 161,
    label: "sénégalaise",
    value: "Sénégal"
  },
  {
    key: 176,
    label: "tadjike",
    value: "Tadjikistan"
  },
  {
    key: 178,
    label: "tanzanienne",
    value: "Tanzanie"
  },
  {
    key: 177,
    label: "taïwanaise",
    value: "Taïwan"
  },
  {
    key: 179,
    label: "tchadienne",
    value: "Tchad"
  },
  {
    key: 180,
    label: "thaïlandaise",
    value: "Thaïlande"
  },
  {
    key: 181,
    label: "timoraise",
    value: "Timor oriental"
  },
  {
    key: 182,
    label: "togolaise",
    value: "Togo"
  },
  {
    key: 183,
    label: "tongienne",
    value: "Tonga"
  },
  {
    key: 184,
    label: "trinidadienne",
    value: "Trinité-et-Tobago"
  },
  {
    key: 185,
    label: "tunisienne",
    value: "Tunisie"
  },
  {
    key: 186,
    label: "turkmène",
    value: "Turkménistan"
  },
  {
    key: 187,
    label: "turque",
    value: "Turquie"
  },
  {
    key: 188,
    label: "tuvaluane",
    value: "Tuvalu"
  },
  {
    key: 189,
    label: "ukrainienne",
    value: "Ukraine"
  },
  {
    key: 190,
    label: "uruguayenne",
    value: "Uruguay"
  },
  {
    key: 191,
    label: "vanuataise",
    value: "Vanuatu"
  },
  {
    key: 192,
    label: "vénézuélienne",
    value: "Venezuela"
  },
  {
    key: 193,
    label: "vietnamienne",
    value: "Viêt Nam"
  },
  {
    key: 194,
    label: "yéménite",
    value: "Yémen"
  },
  {
    key: 195,
    label: "zambienne",
    value: "Zambie"
  },
  {
    key: 196,
    label: "zimbabwéenne",
    value: "Zimbabwe"
  }
];
