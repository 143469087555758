import React, { useEffect, useState } from "react";
import DisplayInformation from "../../commun/displayInformation";
import DisplayInformationStyle from "../updateEmployee/displayInformationStyle";
import {infoban} from "../../../helpers/constants";

export default function EmployeeBank({employee}) {
  const [displayBankList, setDisplayBankList] = useState([]);

  useEffect(
    () => {

    },
    [employee]
  );
    return (
        <div>
            <DisplayInformationStyle icon={infoban}
                                     title={"Coordonnées bancaires"}
                                     inf1={{
                                         title: "Titulaire",
                                         value: employee.bankDetails && employee.bankDetails.owner ? employee.bankDetails.owner : "ND"
                                     }}
                                     inf2={{
                                         title: "RIB",
                                         value: employee.bankDetails && employee.bankDetails.rib ? employee.bankDetails.rib : "ND"
                                     }}
                                     inf3={{
                                         title: "IBAN",
                                         value: employee.bankDetails && employee.bankDetails.iban ? employee.bankDetails.iban : "ND"
                                     }}
                                     inf5={{
                                         title: "Banque",
                                         value: employee.bankDetails && employee.bankDetails.bank ? employee.bankDetails.bank : "ND"
                                     }}
                                     inf6={{
                                         title: "BIC",
                                         value: employee.bankDetails && employee.bankDetails.bic ? employee.bankDetails.bic : "ND"
                                     }}
            />
        </div>
    );
}
