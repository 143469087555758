import Employees from "../views/employees/employees"
import EmployeeForm from "../views/employees/create/new.employee";
import AdaEmployeeUpdate from "../views/employees/updateEmployee/AdaEmployeeUpdate";
import Maintenance from "../views/pages/error/maintenance";

const employeeRoutes = [
    {path: "/employees", component: Employees},
    {path: "/employees/new", component: EmployeeForm},
    {path: "/employees/:Id/edit", component: AdaEmployeeUpdate},
    { path: "/maintenance", component: Maintenance}
];

export default employeeRoutes;