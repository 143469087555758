import IntlMessages from "../../../helpers/intlMessages";
import React from "react";

export const  columnsEmployee = [

    {
        name: <IntlMessages
            id="employee.full.name"
        />,
        selector: "name",
        sortable: false,
        filtrable: true,
        cell : row => <h2>{row.civility}.{row.firstName} {row.lastName}</h2>
    },
    {
        name: <IntlMessages
            id="common.contacts.email"
        />,
        selector: "enable",
        sortable: false,
        filtrable: false,
        format: row => (row.contactInformation &&  row.contactInformation.email ? row.contactInformation.email : "")
    },
    {
        name: <IntlMessages
            id="employee.matricule"
        />,
        selector: "code",
        sortable: false,
        filtrable: true,
    },
    /*{
        name: <IntlMessages
            id="employee.statut"
        />,
        selector: "contract.professionalStatus",
        sortable: false,
        filtrable: false,
    },
    {
        name: <IntlMessages
            id="employee.contractType"
        />,
        selector: "contract.contractType",
        sortable: false,
        filtrable: false,
    },
    {
        name: <IntlMessages
            id="employee.activityType"
        />,
        selector: "contract.activityType",
        sortable: false,
        filtrable: false,
    },*/
    {
        name: <IntlMessages
            id="employee.actif"
        />,
        selector: "enable",
        sortable: false,
        filtrable: false,
        format: row => (row.enable === true ? <IntlMessages id="employee.actif"/> : <IntlMessages id="employee.inactif"/>)
    },
];
