import React, {useEffect, useRef, useState} from 'react'

const Accordion = props => {
    const [active, setActive] = useState(false)
    const contentRef = useRef(null)

    useEffect(() => {
        contentRef.current.style.maxHeight = active ? `${contentRef.current.scrollHeight}px` : '0px'
    }, [contentRef, active])

    useEffect(() => {
        contentRef.current.style.maxHeight = active || props.edit ? `${contentRef.current.scrollHeight}px` : '0px'
    }, [props.edit, active])


    const toogleActive = () => {
        setActive(!active)
    }

    const titleStyle = {
        fontWeight: 700,
        fontSize: '14px',
    }
    return (
        <div className="accordion-section">
            <button className="accordion-title" onClick={toogleActive}>
                <p style={titleStyle}>{props.title}</p>
                <span className={active ? 'accordion-icon rotate' : 'accordion-icon'}>
          >
        </span>
            </button>
            <div
                ref={contentRef}
                className="accordion-content"
            >
                {props.children}
            </div>
        </div>
    )
}

export default Accordion