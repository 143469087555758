
import filtersAction from './actions'
import {GetOrganizationIdFromSession} from "../../service/session.service";

const initState = {
    filters: {
        "firstName":"",
        "lastName": "",
        "organization": GetOrganizationIdFromSession(),
        "professionalStatus": "",
        "activityType": "",
        "function": "",
        "category": "",
        "code": "",
        "email": ""
    
    }
}


export default function rootReducer(state = initState, action) {

    switch (action.type) {
        case filtersAction.UPDATE_FILTERS:
            return {
                ...state,
                filters: action.filters,
            }
        default:
            return state
    }
}