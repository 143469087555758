import React, {useState} from "react";
import {columnsEmployee} from "./data/columns";
import {AdaFilterSubHeader, AdaTable, AdaActions, AdaButton, getConfigObject, getOrganizationSelected} from "@adaming/ada-react-component";
import IntlMessages from "../../helpers/intlMessages";
import EmployeeDetails from "./details/employeeDetails";
import {withRouter} from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import {element} from "prop-types";
import * as service from '../../service/crud.service'
import {EMPLOYEE_NAME, getHostByAppName} from "../../service/host";
import AdvancedSearch from "./search/advanced.search";
import {GetOrganizationIdFromSession} from "../../service/session.service";
import {connect} from "react-redux";
import filtersAction from "../../redux/filters/actions";
import FileSaver from "file-saver";
import axios from "axios";

const {updateFilters} = filtersAction;

const context = "employees";
const Employees = (props) => {
    const [employees, setEmployees] = useState([]);
    const [loadingModal, setLoadingModal] = React.useState(false);
    /*employees detail */
    const [hideEmployeeDetailPanel, setHideEmployeeDetailPanel] = useState(true);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*/
    const [modalShow, setModalShow] = React.useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    /*Error */
    const [modalErrorShow, setModalErrorShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);
    const [advancedSearchShow, setAdvancedSearchShow] = React.useState(false);


    const paginationRowsPerPageOptions = [5,10,20,100]
    const defaultSizePerPage = paginationRowsPerPageOptions[1];
    const defaultPage = 0;
    const [currentPaginationPerRow, setCurrentPaginationPerRow] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [maxContent, setMaxContent] = useState(50);

    const initActionsList = () => {
        return [
            {
                actionName: <IntlMessages id={`action.common.delete`}/>,
                href: "",
                actionHandler: handlerOpenModalDelete,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`action.common.edit`}/>,
                href: `/${context}/` + "" + `/edit`,
                actionHandler: null,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`action.common.activer`}/>,
                href: "",
                actionHandler: handlerActivateAction,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`action.common.desactiver`}/>,
                href: "",
                actionHandler: handlerDisableAction,
                disabled: true
            }
        ];
    };

    /*Initialization Employees List */
    React.useEffect(
        () => {
            if (!modalSuccessShow) {
                initList();
            }
        },
        [modalSuccessShow]
    );

    const initList = () =>{
    }

    const callbackRes = res =>{
        setEmployees(res.data);
        setFiltered(res.data);
        setMaxContent(res.headers['x-content-max'])
    }

    const callbackError = error =>{
        console.log("Error", error);
        setErrorMessage("Erreur lors du téléchargement : "+ error.message);
        setModalErrorShow(true)
    }


    /*Create operation List*/
    React.useEffect(
        () => {
            let actionsValue = initActionsList();
            setActionsList(actionsValue);
            setHideEmployeeDetailPanel(true);
            if (selectedRows !== undefined && selectedRows.length > 0) {
                /*Activate : Delete Action*/
                actionsValue[0] = {...actionsValue[0], disabled: false};
                /*Activate : Activate User Action*/
                actionsValue[2] = {...actionsValue[2], disabled: false};
                /*Activate : Disable User Action*/
                actionsValue[3] = {...actionsValue[3], disabled: false};
                if (selectedRows.length === 1) {
                    /*Activate : Edit User Action*/
                    actionsValue[1] = {
                        ...actionsValue[1],
                        href: `/${context}/${selectedRows[0].id}/edit`,
                        disabled: false
                    };
                    /*show user detail true*/
                    setHideEmployeeDetailPanel(false);
                }
                setActionsList(actionsValue);
            }
        },
        [selectedRows.length]
    );
    React.useEffect(()=>{     
        handlerAdvancedSearch(props.updateFilters({...props.filters.filters, enable: true, disable: false, byEnable: true, organization : getOrganizationSelected().id}).filters, currentPaginationPerRow, currentPage);
    },[currentPaginationPerRow,currentPage])


    const handlerOpenModalDelete = () => {
        setModalShow(true);
    };

    const handlerActivateAction = () => {
        setLoadingModal(true);
        handlerEnableDisableEmployee(selectedRows.map(element => element.id), true);
    };

    const handlerDisableAction = () => {
        setLoadingModal(true);
        handlerEnableDisableEmployee(
            selectedRows.map(element => element.id),
            false
        );
    };

    const handlerEnableDisableEmployee = (ids, status) => {
        service.putSpe(getHostByAppName(EMPLOYEE_NAME),`employees/enable_disable_employees/`,
            (data)=>console.log(data),
            (error)=>console.log(error),
            {
                status: status,
                ids: ids
            },finallyExec)

    };

    const finallyExec = ()=>{
        setLoadingModal(false);
        setModalSuccessShow(true);
    }

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const handlerDeleteAction = () => {
        service.deletes(getHostByAppName(EMPLOYEE_NAME), context, callBackResDelete, ((error)=>{console.log(error)}), selectedRows.map(obj => obj.id))
    };

    const callBackResDelete = () => {
        setModalShow(false);
        setModalSuccessShow(true);
    }

    const handlerAdvancedSearch = React.useCallback((data,size,page) => {
        if(size == null){
            size = defaultSizePerPage;
        }

        if(page == null){
            page = defaultPage;
        }
        service.search(
            getHostByAppName(EMPLOYEE_NAME),
            `${context}/advanced_search/get?size=${size}&page=${page}&sort=lastName,ASC`,
            callbackRes,
            (error) => {
                console.log(error);
            },
            data
        );
    }, []);

    const handlerCancelAdvancedSearch = React.useCallback(() => {
        initList();
    }, []);

    const handleExportExcel = ()  => {
        var config = getConfigObject();
        config.responseType = 'blob';
        axios.put(getHostByAppName(EMPLOYEE_NAME)+'/'+context+'/export/excel/v2', props.filters.filters, config).then(res => {
            const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `all-employee.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);

        }).catch(function (error) {
            callbackError(error); 
        });
    }
      
    const actionsComponent = (
        <div className="flex"> 
            <AdaButton style={{marginRight: 10, height : 27 , marginTop: 5}} onClick={() =>handleExportExcel()} >
                <IntlMessages id="acion.common.export.excel"/>
            </AdaButton> 
            <AdaActions context={context} actionsObj={actionsList} selectedRows={selectedRows}
                    showActions={{add:true}} host={`${getHostByAppName(EMPLOYEE_NAME)}`}/>
        </div>
    );



    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <fieldset>
                                <legend>
                                    <div className="display-3 grey--text">
                                        <IntlMessages id="common.advanced.research"/>

                                        {!advancedSearchShow && (
                                            <i
                                                className="fas fa-search-plus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                        {advancedSearchShow && (
                                            <i
                                                className="fas fa-search-minus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                    </div>
                                </legend>
                                {advancedSearchShow && (
                                    <AdvancedSearch handlerAdvancedSearch={handlerAdvancedSearch}
                                                    handlerCancelAdvancedSearch={handlerCancelAdvancedSearch}/>
                                )}
                            </fieldset>
                        </div>
                    </div>
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <AdaTable
                                columns={columnsEmployee}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                                paginationServer={true}
                                paginationTotalRows={maxContent}
                                paginationPerPage={currentPaginationPerRow}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangeRowsPerPage={(rowPerPage) =>{
                                    setCurrentPaginationPerRow(rowPerPage)
                                }}
                                onChangePage={page => {
                                    setCurrentPage(page - 1);
                                }}
                            />
                            <EmployeeDetails
                                hideDetails={hideEmployeeDetailPanel}
                                selectedElement={
                                    selectedRows.length === 1 ? selectedRows[0] : null
                                }
                            />

                            <SweetAlert
                                onClick={props.processAction}
                                warning
                                showCancel
                                show={modalShow}
                                confirmBtnText="Oui"
                                confirmBtnBsStyle="danger"
                                cancelBtnBsStyle="default"
                                title="Confirmation"
                                onConfirm={() => handlerDeleteAction()}
                                onCancel={() => setModalShow(false)}
                            >
                                <IntlMessages id="action.common.confirmation"/>
                            </SweetAlert>

                            <SweetAlert
                                success
                                show={modalSuccessShow}
                                title="Success!"
                                onConfirm={() => setModalSuccessShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                            >
                                <IntlMessages id="action.common.succes"/>
                            </SweetAlert>
                            <SweetAlert
                                show={modalErrorShow}
                                onConfirm={() => setModalErrorShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                                error
                            >
                                {errorMessage}
                            </SweetAlert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        ...state.filters,
        filters: state.filters
    };
};

export default connect(
    mapStateToProps,
    {
        updateFilters
    }
)(Employees);

