
import React from "react";

export const  devise = [
    {
        key: 1,
        label: "EUR",
        value: "EUR"
    },
    {
        key: 2,
        label: "USD",
        value: "USD"
    }
]