import React, {useEffect, useState} from "react";
import {FormGroup, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText} from "@adaming/ada-react-component";

export default function BankModelEmployee({register, setValue, errors, bankDetails, edit}) {
    const [bankDetailsObject, setBankDetails] = useState({});
    useEffect(() => {
        register({name: "bankDetails.bank"});
        register({name: "bankDetails.owner"});
        register({name: "bankDetails.iban"});
        register({name: "bankDetails.rib"});
        register({name: "bankDetails.bic"});
        if(edit){
            if(bankDetails !== undefined){
                console.log("bankDetails",bankDetails)
                setBankDetails(bankDetails)
            }else{
                setBankDetails({bank : "", owner : "", rib : "", iban : "", bic : ""})
            }
        }
    },[]);

    return (
        <div>
            <FormGroup>
                <IntlMessages id="common.banque"/>
                <AdaInputText
                    name="bankDetails.bank"
                    defaultValue={bankDetailsObject.bank}
                    errors={errors}
                    innerRef={register}
                />
            </FormGroup>

            <FormGroup>
                <IntlMessages id="common.titulaire"/>
                <AdaInputText
                    name="bankDetails.owner"
                    defaultValue={bankDetailsObject.owner}
                    errors={errors}
                    innerRef={register}
                />
            </FormGroup>

            <FormGroup>
                <IntlMessages id="common.iban"/>
                <AdaInputText
                    name="bankDetails.iban"
                    defaultValue={bankDetailsObject.iban}
                    errors={errors}
                    innerRef={register}
                />
            </FormGroup>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="common.rib"/>
                        <AdaInputText
                            name="bankDetails.rib"
                            defaultValue={bankDetailsObject.rib}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="common.bic"/>
                        <AdaInputText
                            name="bankDetails.bic"
                            defaultValue={bankDetailsObject.bic}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}
