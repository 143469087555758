import React, {useEffect, useState} from "react";
import {CustomInput, FormGroup, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import InputMask from "react-input-mask";
import * as service from "../../../service/crud.service";
import {getHostByAppName, IAM_NAME, MAIL_NAME, NOMENCLATURE_NAME, ORGANIZATION_NAME, EMPLOYEE_NAME} from "../../../service/host";
import {getAllPersonSelect} from "../../../service/employee.service";



export default function InformationModelEmployee({icon, title, register, setValue, errors, employee, edit, children}) {
    const [functionList, setFunctionList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [roleSelected, setRoleSelected] = useState();

    const [etablissementList, setEtablissementList] = useState([]);
    const [etablissementSelected, setEtablissementSelected] = useState();

    const [recrutementOfficerList, setRecrutementOfficerList] = useState([]);
    const [recrutementOfficerSelected, setRecrutementOfficerSelected] = useState();

    useEffect(() => {
        register({name: "firstName"});
        register({name: "lastName"});
        register({name: "roles"});
        register({name: "etablissement"});
        register({name: "recrutementOfficer"});
        register({name: "function"});
        register({name: "category"});
        register({name: "socialSecurity.socialSecurityNumber"});
        register({name: "socialSecurity.membershipDate"});        
        setValue("function", null);
        setValue("category", null);

        service.getAllForSelect(getHostByAppName(IAM_NAME), `roles/generic`, setRoleList)
        service.getAllForSelect(getHostByAppName(ORGANIZATION_NAME), `etablissements/generic`, setEtablissementList)
        getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees/internals`, setRecrutementOfficerList)

        service.getAllForSelect(
            getHostByAppName(NOMENCLATURE_NAME),
            `company_functions/`,
            setFunctionList,
            error => console.log(error)
        );

        service.getAllForSelect(
            getHostByAppName(NOMENCLATURE_NAME),
            `company_categorys/`,
            setCategoryList,
            error => console.log(error)
        );

        service.get(getHostByAppName(MAIL_NAME), `employee_setting`, (data) => {
            if (data !== undefined && data[0] !== undefined && data[0].roles !== undefined) {
                let listSelectedRoles = [];
                data[0].roles.forEach(item => {
                        listSelectedRoles.push({
                            key: item.id,
                            label: item.name,
                            value: item.id,
                            object: item
                        })
                    }
                );
                setValue("roles", data[0].roles);
                setRoleSelected(listSelectedRoles);
            }
        }, () => {
        });

    }, []);

    const handleChangeFunction = selectedOption => {
        console.log("function", selectedOption);
        if (selectedOption !== null) {
            setValue("function", selectedOption.value);
        } else {
            setValue("function", undefined);
        }
    };

    const handleChangeCategory = selectedOption => {
        console.log("category", selectedOption);
        if (selectedOption !== null) {
            setValue("category", selectedOption.value);
        } else {
            setValue("category", undefined);
        }
    };

    const handleChangeEtablissement = selectedOption => {
        if (selectedOption !== null) {
            setValue("etablissement", selectedOption);
        } else {
            setValue("etablissement", undefined);
        }
        setEtablissementSelected(selectedOption);
    };

    const handleChangeRecrutementOfficer = selectedOption => {
        if (selectedOption !== null) {
            setValue("recrutementOfficer", selectedOption.object);
        } else {
            setValue("recrutementOfficer", undefined);
        }
        setRecrutementOfficerSelected(selectedOption);
    };

    const handleChangeRole = selectedOption => {
        let roles = [];
        if (selectedOption) {
            selectedOption.forEach(item => {
                roles.push(item.object)
            });
        }
        setValue("roles", roles);
        setRoleSelected(selectedOption);
    };

    return (
        <div>

            <FormGroup>
                <div>
                    <Row>
                        <Col
                            className="col-sm-2"
                            style={{"margin-top": "10px"}}
                        >
                            <CustomInput
                                id="mme"
                                type="radio"
                                name="civility"
                                value="Mme"
                                defaultChecked={employee !== undefined ? (employee.civility === "Mme" ? true : false) : true}
                                innerRef={register}
                                label={
                                    <IntlMessages id="employee.civilite.mme"/>
                                }
                            />

                            <CustomInput
                                id="mr"
                                type="radio"
                                name="civility"
                                defaultChecked={employee !== undefined ? (employee.civility === "Mr" ? true : false) : false}
                                value="Mr"
                                innerRef={register}
                                label={
                                    <IntlMessages id="employee.civilite.mr"/>
                                }
                            />
                        </Col>

                        <Col
                            className="col-sm-2"
                            style={{"margin-top": "20px"}}
                        >
                            <FormGroup>
                                <CustomInput
                                    type="switch"
                                    id="enableContactClt"
                                    name="enable"
                                    defaultChecked={employee !== undefined ? employee.enable : true}
                                    innerRef={register}
                                    label={<IntlMessages id="employee.actif"/>}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </FormGroup>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.nom"/>
                        <AdaInputText
                            name="firstName"
                            defaultValue={employee !== undefined ? employee.firstName : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.prenom"/>
                        <AdaInputText
                            name="lastName"
                            defaultValue={employee !== undefined ? employee.lastName : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="common.telephone"/>
                        <AdaInputText
                            name="contactInformation.phone"
                            defaultValue={employee !== undefined ? employee.contactInformation.phone : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="common.contacts.mobile"/>
                        <AdaInputText
                            name="contactInformation.mobile"
                            defaultValue={employee !== undefined ? employee.contactInformation.mobile : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.function"/>
                        <AdaSelect
                            name="function"
                            errors={errors}
                            isClearable
                            options={functionList}
                            onChange={handleChangeFunction}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.category"/>
                        <AdaSelect
                            name="category"
                            errors={errors}
                            isClearable
                            options={categoryList}
                            onChange={handleChangeCategory}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="common.contacts.email"/>
                        <AdaInputText
                            name="contactInformation.email"
                            defaultValue={employee !== undefined ? employee.contactInformation.email : ""}
                            type="email"
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.learning.type"/>
                        <AdaInputText
                            name="skillEmployeeType.learningType"
                            defaultValue={employee !== undefined && employee.skillEmployeeType ? employee.skillEmployeeType.learningType : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.security.number"/>
                        <AdaInputText
                            type="text"
                            name="socialSecurity.socialSecurityNumber"
                            defaultValue={employee !== undefined ? employee.socialSecurity.socialSecurityNumber : ""}
                            mask="9 99 99 99 999 999 99"
                            maskChar=" "
                            errors={errors}
                            innerRef={register}
                            tag={InputMask}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.security.membership.date"/>
                        <AdaInputText
                            type="date"
                            name="socialSecurity.membershipDate"
                            defaultValue={employee !== undefined ? employee.socialSecurity.memershipDate : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.etablissement"/>
                        <AdaSelect
                            name="etablissement"
                            value={etablissementSelected}
                            errors={errors}
                            options={etablissementList}
                            onChange={handleChangeEtablissement}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>

                    <FormGroup>
                        <IntlMessages id="employee.recrutement.officer"/>
                        <AdaSelect
                            name="recrutementOfficer"
                            value={recrutementOfficerSelected}
                            errors={errors}
                            isClearable
                            options={recrutementOfficerList}
                            onChange={handleChangeRecrutementOfficer}
                            innerRef={register}
                        />
                    </FormGroup>
                    
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.role"/>
                        <AdaSelect
                            name="roles"
                            isMulti
                            value={roleSelected}
                            errors={errors}
                            options={roleList}
                            onChange={handleChangeRole}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="pt-3 pb-3">
                {children}
            </Row>


        </div>
    );
}
