const filtersAction = {
    UPDATE_FILTERS: 'UPDATE_FILTERS',
    
    updateFilters: (data) => {
        return {
            type: filtersAction.UPDATE_FILTERS,
            filters: data
        };
    }
}

export default filtersAction