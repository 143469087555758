import React, {useEffect} from "react";
import {CustomInput, FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import ContractModelEmployee from "../model/contract.model";
import {contract, identity} from "../../../helpers/constants";

export default function ContractEmployee(props) {
    const {register, handleSubmit, errors, setValue} = useForm();

    const onSubmit = data => {
        console.log("data trans", data)
        props.updateAction({
            contract: {
                ...data.contract, remuneration: {
                    annualSalary: parseFloat(data.contract.remuneration.annualSalary),
                    monthlySalary: parseFloat(data.contract.remuneration.monthlySalary),
                    dailySalary: parseFloat(data.contract.remuneration.dailySalary),
                    transportationAllowance: parseFloat(data.contract.remuneration.transportationAllowance),
                    mealAllowance: parseFloat(data.contract.remuneration.mealAllowance),
                    currency: data.contract.remuneration.currency
                },
                code: new Date()
            }
        });

        props.step("4");
    };

    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style col-sm-12 row">
                            <div className="roe-card-body col-sm-4">
                                <div className="pl-5 pt-5 aligner-wrapper text-center">
                                    <img className="pt-5"
                                         src={contract}
                                         style={{width:"200px"}}
                                    />
                                    <div style={{marginTop:"10px"}}>
                                        <h3 className="card-title fs-25 bold-text">Contrat</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="roe-card-body col-sm-8">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col>
                                            <ContractModelEmployee register={register} setValue={setValue}
                                                                   errors={errors}/>
                                            <br/>
                                            <FormGroup>
                                                <AdaButton type="submit" className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                                                    <IntlMessages id="action.suivant.contact"/>
                                                </AdaButton>

                                                <AdaButton onClick={() => props.step("2")}
                                                           className="no-border c-outline-AdaBack c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                                                    <IntlMessages id="action.precedent.bank.information"/>
                                                </AdaButton>

                                                <AdaButton
                                                    className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                    onClick={props.cancelAction}
                                                >
                                                    <IntlMessages id="action.common.cancel"/>
                                                </AdaButton>

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
