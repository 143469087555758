import React, { useEffect, useState } from "react";
import {
  Card,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label
} from "reactstrap";
import classnames from "classnames";
import IntlMessages from "../../../helpers/intlMessages";
import { useHistory } from "react-router-dom";
import InformationEmployee from "./information";
import CivilityInformation from "./civility";
import AddressEmployee from "./address";
import BankEmployee from "./bank";
import ContractEmployee from "./contract";
import ContactsEmployee from "./contacts";
import EmployeeContacts from "./contacts.employee.sb"
import * as service from "../../../service/crud.service";
import {EMPLOYEE_NAME, getHostByAppName} from "../../../service/host";
import {GetOrganizationIdFromSession} from "../../../service/session.service";

export default function EmployeeForm(props) {
  const [activeTab, setActiveTab] = useState("1");
  const [employee, setEmployee] = useState({});
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  let history = useHistory();

  const step = tab => {
    console.log("in step ", tab);
    if (activeTab !== tab) setActiveTab(tab);
  };

  const cancel = () => {
    history.push("/employees");
  };

  const setEmployeeInformation = data => {
    setEmployee({ ...employee, ...data, organization: GetOrganizationIdFromSession() });
    console.log("data in add Employee :", data);
    console.log("Employee :", employee);
  };

  const saveEmployee = object => {
    setLoadingModal(true);
    setErrorMessage("");

    console.log("save Employee :");
    console.log(JSON.stringify({ ...employee, ...object }));
    service.post(getHostByAppName(EMPLOYEE_NAME),"employees", callBackRes, callBackError, { ...employee, ...object });

  };

  const callBackRes = (res) => {
    console.log(res.data);
    history.push(`/employees/${res.data.id}/edit`);
  }

  const callBackError = (error) => {
    setErrorMessage(error.toString());
    setLoadingModal(false);
  }

  useEffect(() => {}, [activeTab]);

  return (
    <div>
      <h4>Ajouter un Collab : </h4>
      <br />
      <Label>
        <p style={{ color: "red" }}> {errorMessage}</p>
      </Label>
      <Nav tabs>
        <NavItem className="roe-card-body">
          <NavLink className={classnames({ active: activeTab === "1" })}>
            <IntlMessages id={`common.information`} />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === "2" })}>
            <IntlMessages id={`common.cord.bancaires`} />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === "3" })}>
            <IntlMessages id={`employee.contract`} />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === "4" })}>
            <IntlMessages id={`common.contacts`} />
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <Card body>
                <CardTitle />
                <CardText>
                  <InformationEmployee
                    step={step}
                    cancelAction={cancel}
                    clientObj={employee}
                    updateAction={setEmployeeInformation}
                  />
                </CardText>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Col sm="15">
            <Card body>
              <CardText>
                <BankEmployee
                  step={step}
                  cancelAction={cancel}
                  clientObj={employee}
                  updateAction={setEmployeeInformation}
                />
              </CardText>
            </Card>
          </Col>
        </TabPane>
        <TabPane tabId="3">
          <Col sm="15">
            <Card body>
              <CardText>
                <ContractEmployee
                  step={step}
                  cancelAction={cancel}
                  clientObj={employee}
                  updateAction={setEmployeeInformation}
                />
              </CardText>
            </Card>
          </Col>
        </TabPane>
        <TabPane tabId="4">
          <Col sm="15">
            <Card body>
              <CardText>
                <EmployeeContacts
                    step={step}
                    cancelAction={cancel}
                    clientObj={employee}
                    saveEmployee={saveEmployee}
                    updateAction={setEmployeeInformation}
                />
              </CardText>
            </Card>
          </Col>
        </TabPane>
      </TabContent>
    </div>
  );
}
