import React, {useEffect, useState} from "react";
import Gauge from "react-svg-gauge";
import UserInfoWrapper from "./userinfo.style";


const DisplayInformationStyle = ({icon, title, description, inf1, inf2, inf3, inf4, inf5, inf6, inf7, inf8}) => {

    return (
        <UserInfoWrapper className="fill-height pt-2">
            <div className="card roe-shadow-2 fill-height">
                <div className="card-body">

                    <div className="row">
                        <div className="col-md-4 aligner-wrapper text-center">
                            {icon && <img
                                src={icon}
                                style={{width:"140px"}}
                            />}
                            <div style={{marginTop:"10px"}}>
                                <h3 className="card-title fs-20 bold-text">{title}</h3>
                            </div>
                        </div>
                        <div className="col-md-4 legend-wrapper">
                            {inf1 && <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                    <h4 className="mb-0 text-dark fs-15 bold-text" >{inf1.value}</h4>
                                </div>
                                <small className="text-muted ml-3">{inf1.title}</small>
                            </div>}
                            {inf2 && <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                    <h4 className="mb-0 text-dark fs-15 bold-text">{inf2.value}</h4>
                                </div>
                                <small className="text-muted ml-3">{inf2.title}</small>
                            </div>}
                            {inf3 && <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                    <h4 className="mb-0 text-dark fs-15 bold-text">{inf3.value}</h4>
                                </div>
                                <small className="text-muted ml-3">{inf3.title}</small>
                            </div>}
                            {inf4 && <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                    <h4 className="mb-0 text-dark fs-15 bold-text">{inf4.value}</h4>
                                </div>
                                <small className="text-muted ml-3">{inf4.title}</small>
                            </div>}
                        </div>

                        <div className="col-md-4 legend-wrapper">
                            {inf5 && <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                    <h4 className="mb-0 text-dark fs-15 bold-text">{inf5.value}</h4>
                                </div>
                                <small className="text-muted ml-3">{inf5.title}</small>
                            </div>}
                            {inf6 && <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                    <h4 className="mb-0 text-dark fs-15 bold-text">{inf6.value}
                                    </h4>
                                </div>
                                <small className="text-muted ml-3">{inf6.title}</small>
                            </div>}
                            {inf7 && <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                    <h4 className="mb-0 text-dark fs-15 bold-text">{inf7.value}</h4>
                                </div>
                                <small className="text-muted ml-3">{inf7.title}</small>
                            </div>}

                            {inf8 && <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                    <h4 className="mb-0 text-dark fs-15 bold-text">{inf8.value}</h4>
                                </div>
                                <small className="text-muted ml-3">{inf8.title}</small>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </UserInfoWrapper>
    );
};

export default DisplayInformationStyle;
