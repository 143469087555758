import React, {useEffect} from "react";
import {
    CustomInput,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../helpers/intlMessages";
import {useForm} from "react-hook-form";
//import AdaSelectBuilder from "../../views/commun/ada.select.builder";
import {getHostByAppName, NOMENCLATURE_NAME} from "../../service/host";

const ContactForm = ({
                         listEntities,
                         listResponsible,
                         showContactModal,
                         close,
                         addNewItem,
                         editObject,
                         dispatch,
                         selectedObjects
                     }) => {
    const {
        register,
        errors,
        setValue,
        reset,
        handleSubmit,
        control
    } = useForm();

    useEffect(() => {

    }, []);

    useEffect(
        () => {
                console.log("editObject in form", editObject);
        },
        [editObject]
    );


    const submit = data => {
        console.log("data in modal contact form ", data);
        addNewItem({id: editObject.id ,...data,code: new Date()});
        reset();
    };

    return (
        <div>
            <Modal centered size="lg" isOpen={showContactModal} fade={false}>
                <ModalHeader>
                    <div className="mb-0 fs-22 bold-text" style={{color:"#FF0B0E"}}><IntlMessages id="common.contact"/> </div>
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit(submit)}>
                        <Row>
                            <Col>
                                <Label/>

                                <FormGroup>
                                    <div>
                                        {/*<Row>
                                            <Col
                                                className="col-sm-2"
                                                style={{"margin-top": "10px"}}
                                            >
                                                <AdaSelectBuilder
                                                    errors={errors}
                                                    register={register}
                                                    setValue={setValue}
                                                    uri={`${getHostByAppName(NOMENCLATURE_NAME)}/civilitys`}
                                                    inputName={"civility"}
                                                    optionsType={"string"}
                                                    control={control}
                                                    dispatch={dispatch}
                                                />
                                            </Col>
                                            <Col
                                                className="col-sm-2"
                                                style={{"margin-top": "20px"}}
                                            >
                                                <FormGroup>
                                                    <CustomInput
                                                        type="switch"
                                                        id="actifContactClt1"
                                                        name="actif"
                                                        defaultChecked={editObject.actif !== undefined ? editObject.actif : true}
                                                        innerRef={register}
                                                        label={<IntlMessages id="crm.common.status"/>}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>

                                            </Col>
                                        </Row>*/}
                                    </div>
                                </FormGroup>
                                <Row>
                                    <Col>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="employee.prenom"/>
                                            <AdaInputText
                                                name="firstName"
                                                defaultValue={
                                                    editObject !== undefined ? editObject.firstName : ""
                                                }
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="employee.nom"/>
                                            <AdaInputText
                                                name="lastName"
                                                defaultValue={
                                                    editObject !== undefined ? editObject.lastName : ""
                                                }
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="common.telephone"/>
                                            <AdaInputText
                                                name="phone"
                                                defaultValue={
                                                    editObject !== undefined &&
                                                    editObject.contactInformation !== undefined
                                                        ? editObject.contactInformation.phone
                                                        : ""
                                                }
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="common.contacts.mobile"/>
                                            <AdaInputText
                                                name="mobile"
                                                defaultValue={
                                                    editObject !== undefined &&
                                                    editObject.contactInformation !== undefined
                                                        ? editObject.contactInformation.mobile
                                                        : ""
                                                }
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="common.contacts.email"/>
                                            <Input
                                                name="email"
                                                type="email"
                                                defaultValue={
                                                    editObject !== undefined &&
                                                    editObject.contactInformation !== undefined
                                                        ? editObject.contactInformation.email
                                                        : ""
                                                }
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <IntlMessages id="common.contacts.fax"/>
                                            <AdaInputText
                                                name="fax"
                                                defaultValue={
                                                    editObject !== undefined &&
                                                    editObject.contactInformation !== undefined
                                                        ? editObject.contactInformation.fax
                                                        : ""
                                                }
                                                errors={errors}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>

                                    </Col>

                                    <Col>

                                    </Col>
                                </Row>

                                <Row className="col-sm-12 mt-3">
                                    <Col className="col-sm-8"/>
                                    <Col>
                                        <FormGroup>
                                            <AdaButton className="c-btn c-danger w-100" onClick={() => close()}>
                                                <IntlMessages id="action.common.cancel"/>
                                            </AdaButton>

                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <AdaButton className="c-btn c-warning w-100" type="submit" style={{marginLeft: 10}}>
                                                <IntlMessages id="action.common.submit"/>
                                            </AdaButton>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ContactForm;
