import React, {useEffect, useState} from "react";
import {
    FormGroup
} from "reactstrap";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaButton} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import BoardList from "../../../components/scrumboard/BoardList";
//import ContactForm from "../../../../components/scrumboard/ContactForm";
import ScrumboardWrapper from "../../../components/scrumboard/scrumboard.style";
import scrumActions from "../../../redux/scrumboard/actions";
import {connect} from "react-redux";
import ContactForm from "../../../components/scrumboard/ContactForm";
import DiplomaForm from "../../../components/scrumboard/DiplomaForm";
import BoardDiplomaList from "../../../components/scrumboard/BoardDiplomaList";

const context = "contacts";
const {updateBoards} = scrumActions;

const EmployeeDiplomas = props => {

    const {register, handleSubmit, errors, setValue, reset} = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [listDiplomas, setListDiplomas] = React.useState([]);

    const [currentModelAction, setCurrentModelAction] = useState("add");
    const [editedContent, setEditedContent] = useState({});
    const [isAddBoard, setIsAddBoard] = useState(false);
    const [model, setModel] = useState(false);

    useEffect(()=>{
        setListDiplomas(props.employee && props.employee.id && props.employee.otherDataEmployeeType &&  props.employee.otherDataEmployeeType.diplomas ? props.employee.otherDataEmployeeType.diplomas : [])
    },[props.employee])

    const onSubmit = data => {
        console.log("on submit data", data);
        console.log("on submit add diplomas", listDiplomas);
        let contactObj = {
            description: data.description,
            diplomaDate: data.diplomaDate
        };
        let temp = []
        if(data.id !== undefined){
            temp = replaceElementInArray(listDiplomas, contactObj, data.id)

        }else{
            temp = listDiplomas.concat(contactObj)
        }
        setListDiplomas(temp);
        props.onSubmitList(temp)
        setModel(false);
        reset();
    };

    const replaceElementInArray = (oldList, object, index) => {
        let newList = Object.assign([], oldList, {[index]: object});
        return newList;
    };

    const nextStep = () => {
        props.saveEmployee({
            emergencyContacts: listDiplomas
        });
    };

    useEffect(() => {
    }, [listDiplomas]);

    const deleteElementFromList = (key, list) => {
        console.log("key", key);
        let arr = [];
        list.forEach((value, keyLoop) => {
            if (key !== keyLoop) {
                arr.push(value);
            }
        });
        props.onSubmitList(arr)
        return arr;
    };

    const action = (action, object) => {
        console.log("e", object);
        if (action === "add") {
            setCurrentModelAction("add");
            setEditedContent({});
        } else if (action === "edit") {
            setCurrentModelAction("edit");
            setEditedContent(object);
        }
        setModel(true);
    };

    const closeForm = () => {
        setCurrentModelAction("");
        setEditedContent({});
        setModel(false);
    };


    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-10 ptb-15">

                                <ScrumboardWrapper {...props}>

                                            <div className="row ma-0">
                                                {listDiplomas.map((board, i) => {
                                                    return (
                                                        <BoardDiplomaList
                                                            key={i} edit={props.edit}
                                                            unique="personal"
                                                            board={{id: i, ...board}}
                                                            actiononContact={() => {
                                                                setEditedContent({id: i, ...board});
                                                                action("edit", {id: i, ...board});
                                                            }}
                                                            deleteBoardHandler={() =>
                                                                setListDiplomas(
                                                                    deleteElementFromList(i, listDiplomas)
                                                                )
                                                            }
                                                        />
                                                    );
                                                })}


                                                {props.edit && <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-15 ">
                                                    <div
                                                        className="whitelight pa-24 cursor-pointer with-transition roe-box-shadow">
                                                        <div className="board">
                                                            <div
                                                                className="overlay flex-x center"
                                                                onClick={() => action("add", null)}
                                                            >
                                                                <div>
                                                                    <div className="text-center">
                                                                        {isAddBoard ? (
                                                                            <div/>
                                                                        ) : (
                                                                            <div
                                                                                className="fs-20 bold-text board-list-title">
                                                                                <i className="fas fa-plus"/>{" "}
                                                                                <IntlMessages
                                                                                    id="employee.create.diplomas"/>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>

                                </ScrumboardWrapper>
                                {model && (
                                    <DiplomaForm
                                        currentModelAction={currentModelAction}
                                        editObject={editedContent}
                                        addNewItem={onSubmit}
                                        dispatch={props.dispatch}
                                        selectedObjects={props.selectedObjects}
                                        close={closeForm}
                                        showModal={model}
                                    />
                                )}



                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger,
        boards: state.scrumboard.boards
    };
};

export default connect(
    mapStateToProps,
    {
        updateBoards
    }
)(EmployeeDiplomas);
