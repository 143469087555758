import React, {useEffect, useState} from "react";
import {
    FormGroup
} from "reactstrap";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaButton} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import BoardList from "../../../components/scrumboard/BoardList";
//import ContactForm from "../../../../components/scrumboard/ContactForm";
import ScrumboardWrapper from "../../../components/scrumboard/scrumboard.style";
import scrumActions from "../../../redux/scrumboard/actions";
import {connect} from "react-redux";
import ContactForm from "../../../components/scrumboard/ContactForm";

const context = "contacts";
const {updateBoards} = scrumActions;

const EmployeeContactsUpdate = props => {

    const {register, handleSubmit, errors, setValue, reset} = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [listContacts, setListContacts] = React.useState([]);

    const [currentModelAction, setCurrentModelAction] = useState("add");
    const [editedContent, setEditedContent] = useState({});
    const [isAddBoard, setIsAddBoard] = useState(false);
    const [contactModel, setContactModel] = useState(false);

    useEffect(()=>{
        setListContacts(props.employee && props.employee.id && props.employee.emergencyContacts ? props.employee.emergencyContacts : [])
    },[props.employee])

    const onSubmit = data => {
        console.log("on submit data", data);
        console.log("on submit add contact", listContacts);
        let contactObj = {
            code:new Date(),
            civility: data.civility,
            enable: true,
            firstName: data.firstName,
            lastName: data.lastName,
            contactInformation: {
                phone: data.phone,
                mobile: data.mobile,
                email: data.email,
                fax: data.fax
            }
        };
        let temp = []
        if(data.id !== undefined){
            temp = replaceElementInArray(listContacts, contactObj, data.id);

        }else{
            temp = listContacts.concat(contactObj);
        }
        setListContacts(temp);
        props.onSubmitList(temp)
        setContactModel(false);
        reset();
    };

    const replaceElementInArray = (oldList, object, index) => {
        let newList = Object.assign([], oldList, {[index]: object});
        return newList;
    };

    const nextStep = () => {
        props.saveEmployee({
            emergencyContacts: listContacts
        });
    };

    useEffect(() => {
    }, [listContacts]);

    const deleteElementFromList = (key, list) => {
        console.log("key", key);
        let arr = [];
        list.forEach((value, keyLoop) => {
            if (key !== keyLoop) {
                arr.push(value);
            }
        });
        props.onSubmitList(arr)
        return arr;
    };

    const actiononContact = (action, object) => {
        console.log("e", object);
        if (action === "add") {
            setCurrentModelAction("add");
            setEditedContent({});
        } else if (action === "edit") {
            setCurrentModelAction("edit");
            setEditedContent(object);
        }
        setContactModel(true);
    };

    const closeForm = () => {
        setCurrentModelAction("");
        setEditedContent({});
        setContactModel(false);
    };


    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-10 ptb-15">

                                <ScrumboardWrapper {...props}>

                                            <div className="row ma-0">
                                                {listContacts.map((board, i) => {
                                                    return (
                                                        <BoardList
                                                            key={i} edit={props.edit}
                                                            unique="personal"
                                                            board={{id: i, ...board}}
                                                            actiononContact={() => {
                                                                setEditedContent({ ...board, id: i});
                                                                actiononContact("edit", {...board, id: i});
                                                            }}
                                                            deleteBoardHandler={() =>
                                                                setListContacts(
                                                                    deleteElementFromList(i, listContacts)
                                                                )
                                                            }
                                                        />
                                                    );
                                                })}


                                                {props.edit && <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-15 ">
                                                    <div
                                                        className="whitelight pa-24 cursor-pointer with-transition roe-box-shadow">
                                                        <div className="board">
                                                            <div
                                                                className="overlay flex-x center"
                                                                onClick={() => actiononContact("add", null)}
                                                            >
                                                                <div>
                                                                    <div className="text-center">
                                                                        {isAddBoard ? (
                                                                            <div/>
                                                                        ) : (
                                                                            <div
                                                                                className="fs-20 bold-text board-list-title">
                                                                                <i className="fas fa-plus"/>{" "}
                                                                                <IntlMessages
                                                                                    id="crm.common.create.contact"/>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>

                                </ScrumboardWrapper>
                                {contactModel && (
                                    <ContactForm
                                        currentModelAction={currentModelAction}
                                        editObject={editedContent}
                                        addNewItem={onSubmit}
                                        dispatch={props.dispatch}
                                        selectedObjects={props.selectedObjects}
                                        close={closeForm}
                                        showContactModal={contactModel}
                                    />
                                )}



                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger,
        boards: state.scrumboard.boards
    };
};

export default connect(
    mapStateToProps,
    {
        updateBoards
    }
)(EmployeeContactsUpdate);
