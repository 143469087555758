import React, {useEffect, useState} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {familySituation} from "../../commun/enum/familySituation";
import * as service from "../../../service/crud.service";
import {getHostByAppName, NOMENCLATURE_NAME, ORGANIZATION_NAME} from "../../../service/host";

export default function CivilityModelEmployee({register, setValue, errors, employee, edit}) {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [countryList, setCountryList] = useState([]);
    const [familySituationList, setFamilySituationList] = useState([]);
    const [familySituationSelected, setFamilySituationSelected] = useState({});
    const [nativeCountrySelected, setNativeCountrySelected] = useState({});
    const [nationalitySelected, setNationalitySelected] = useState({});

    useEffect(() => {
        service.getAllForSelect(getHostByAppName(NOMENCLATURE_NAME), `countrys`, setCountryList)
        service.getAllForSelect(getHostByAppName(NOMENCLATURE_NAME), `marital_statuss`, setFamilySituationList)
        register({name: "civilState.birthCountry"});
        register({name: "civilState.nationalities"});
        register({name: "civilState.familySituation"});
        setValue("civilState.familySituation", null);
        setValue("civilState.nationalities", null);
        setValue("civilState.birthCountry", null);
        if (edit) {
            if (!employee.civilState.nationalities.isEmpty) {
                console.log("not empty list ")
            }
        } else {

        }
    }, []);

    const handleChangeCountry = selectedOption => {
        console.log("nativeCountry", selectedOption);
        if (selectedOption !== null) {
            setValue("civilState.birthCountry", selectedOption.value);
        } else {
            setValue("civilState.birthCountry", undefined);
        }
    };

    const handleChangeFamilySituation = selectedOption => {
        console.log("familySituation", selectedOption);
        if (selectedOption !== null) {
            setValue("civilState.familySituation", selectedOption.value);
        } else {
            setValue("civilState.familySituation", undefined);
        }
    };

    const handleChangeNationality = selectedOption => {
        console.log("nationality", selectedOption);
        if (selectedOption !== null) {
            setValue(
                "civilState.nationalities",
                selectedOption.map(object => object.value)
            );
        } else {
            setValue("civilState.nationalities", undefined);
        }
    };

    return (
        <div>

            <Label>
                <p style={{color: "red"}}> {errorMessage}</p>
            </Label>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.birthDate"/>
                        <AdaInputText
                            type="date"
                            defaultValue={employee !== undefined ? employee.civilState.birthDate : ""}
                            name="civilState.birthDate"
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.nativeCountry"/>
                        <AdaSelect
                            name="civilState.birthCountry"
                            errors={errors}
                            isClearable
                            options={countryList}
                            onChange={handleChangeCountry}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.birthCity"/>
                        <AdaInputText
                            name="civilState.birthCity"
                            defaultValue={employee !== undefined ? employee.civilState.birthCity : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.birthName"/>
                        <AdaInputText
                            name="civilState.birthName"
                            defaultValue={employee !== undefined ? employee.civilState.birthName : ""}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.familySituation"/>
                        <AdaSelect
                            name="civilState.familySituation"
                            errors={errors}
                            isClearable
                            options={familySituationList}
                            onChange={handleChangeFamilySituation}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.nationality"/>
                        <AdaSelect
                            name="civilState.nationalities"
                            errors={errors}
                            isMulti
                            isClearable
                            options={countryList}
                            onChange={handleChangeNationality}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>


        </div>
    );
}
