import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import {Controller, useForm} from "react-hook-form";
import IntlMessages from "../../../helpers/intlMessages";
import * as service from "../../../service/crud.service";
import * as specificService from "../../../service/employee.service";
import {CRM_NAME, EMPLOYEE_NAME, getHostByAppName, NOMENCLATURE_NAME, ORGANIZATION_NAME} from "../../../service/host";
import {AdaSelect, AdaButton, getOrganizationSelected} from "@adaming/ada-react-component";
import {CustomInput} from "reactstrap";

import {AdaInputText} from "@adaming/ada-react-component";
import AdaSelectBuilder from "../../../components/ada.select.builder";
import {connect} from "react-redux";

import {GetOrganizationIdFromSession} from "../../../service/session.service";
import filtersAction from "../../../redux/filters/actions";
const {updateFilters} = filtersAction;

 const AdvancedSearch = (props) =>  {

    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const [employeeList, setEmployeeList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [entityList, setEntityList] = useState([]);



    const handlerAdvancedSearch = props.handlerAdvancedSearch;
    const handlerCancelAdvancedSearch = props.handlerCancelAdvancedSearch;
    

    useEffect(() => {

    }, [])

    const dispatch = (data) =>{
        props.updateFilters({...props.filters.filters, ...data})
    }

    const onSubmit = (data) => {
        handlerAdvancedSearch({...props.filters.filters, organization : getOrganizationSelected().id});
    }

    const clear = () => {
        reset({
            "firstName": "",
            "lastName": null,
            "organization": GetOrganizationIdFromSession(),
            "professionalStatus": null,
            "activityType": null,
            "function": null,
            "category": null,
            "code": "",
            "email":"",
            "enable": true,
            "disable": false,
            "byEnable": true
        });
        props.updateFilters({
            "firstName": "",
            "lastName": null,
            "organization": GetOrganizationIdFromSession(),
            "professionalStatus": null,
            "activityType": null,
            "function": null,
            "category": null,
            "code": "",
            "email":"",
            "enable": true,
            "disable": false,
            "byEnable": true

        })
        handlerCancelAdvancedSearch();
    }


    return (<div>
            <form onSubmit={handleSubmit(onSubmit)}>



                <Row className="col-sm-12 pt-3">
                    <Col className="col-sm-3">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id="employee.prenom"/>

                                    <AdaInputText
                                        name="firstName" style={{height:38}}
                                        onBlur={(e)=> props.updateFilters({...props.filters.filters, firstName: e.target.value })}
                                        innerRef={register}
                                        defaultValue={props.filters.filters.firstName}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id="employee.nom"/>
                                    <AdaInputText
                                        name="lastName" style={{height:38}}
                                        onBlur={(e)=> props.updateFilters({...props.filters.filters, lastName: e.target.value })}
                                        innerRef={register}
                                        defaultValue={props.filters.filters.lastName}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>


                    <Col className="col-sm-3">
                        <Row>
                            <Col>

                                    <FormGroup>
                                        <IntlMessages id="employee.function"/>

                                        <AdaSelectBuilder errors={errors} register={register}
                                                          setValue={setValue}
                                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/company_functions/`}
                                                          inputName={"function"}
                                                          optionsType={"string"}
                                                          defaultId={null}
                                                          control={control}
                                                          dispatch={dispatch}/>

                                    </FormGroup>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id="employee.category"/>

                                    <AdaSelectBuilder errors={errors} register={register}
                                                      setValue={setValue}
                                                      uri={`${getHostByAppName(NOMENCLATURE_NAME)}/company_categorys/`}
                                                      inputName={"category"}
                                                      optionsType={"string"}
                                                      defaultId={null}
                                                      control={control}
                                                      dispatch={dispatch}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>



                    <Col className="col-sm-3">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id="employee.contractType"/>

                                    <AdaSelectBuilder errors={errors} register={register}
                                                      setValue={setValue}
                                                      uri={`${getHostByAppName(NOMENCLATURE_NAME)}/contract_types/`}
                                                      inputName={"contractType"}
                                                      optionsType={"string"}
                                                      defaultId={null}
                                                      control={control}
                                                      dispatch={dispatch}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id="employee.matricule"/>
                                    <AdaInputText
                                        name="code" style={{height:38}}
                                        onBlur={(e)=> props.updateFilters({...props.filters.filters, code: e.target.value })}
                                        innerRef={register}
                                        defaultValue={props.filters.filters.code}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="col-sm-3">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id="common.contacts.email"/>
                                    <AdaInputText
                                        name="email" style={{height:38}}
                                        onBlur={(e)=> props.updateFilters({...props.filters.filters, email: e.target.value })}
                                        innerRef={register}
                                        defaultValue={props.filters.filters.email}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <CustomInput
                                    id="enable"
                                    type="checkbox"
                                    name="enable"
                                    defaultChecked={props.filters.filters.enable != undefined ? props.filters.filters.enable: false }
                                    innerRef={register}
                                    onChange={(e)=> {props.updateFilters({...props.filters.filters, enable: e.target.checked })}}
                                    label={
                                        <IntlMessages  id="employee.actif" />
                                    }
                                />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <CustomInput
                                    id="disable"
                                    type="checkbox"
                                    name="disable"
                                    defaultChecked={props.filters.filters.disable != undefined ? props.filters.filters.disable: false }
                                    innerRef={register}
                                    onChange={(e)=> {props.updateFilters({...props.filters.filters, disable: e.target.checked })}}
                                    label={
                                        <IntlMessages  id="employee.inactif" />
                                    }
                                />
                            </FormGroup>
                            </Col>
                        </Row>


                        <Row className="pt-4">
                            <Col>
                                <FormGroup>
                                    <AdaButton style={{height: 35}} className="c-danger col-xl-5" onClick={() => clear()}>
                                        <IntlMessages id="action.common.cancel"/>
                                    </AdaButton>
                                    <AdaButton
                                        className="c-warning col-xl-6"
                                        type="submit"
                                        style={{marginLeft: 10, height: 35}}
                                    >
                                        <IntlMessages id="common.search"/>
                                    </AdaButton>
                                </FormGroup>
                            </Col>
                        </Row>

                    </Col>

                </Row>
            </form>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        ...state.filters,
        filters: state.filters
    };
};

export default connect(
    mapStateToProps,
    {
        updateFilters
    }
)(AdvancedSearch);
