import React, {useEffect, useState} from "react";
import {CustomInput, FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {devise} from "../../commun/enum/devise";
import {contractType} from "../../commun/enum/contractType";
import {paymentMethod} from "../../commun/enum/paymentMethod";
import {professionalStatus} from "../../commun/enum/professionalStatus";
import * as service from "../../../service/crud.service";
import {getHostByAppName, NOMENCLATURE_NAME} from "../../../service/host";
import AdaSelectBuilder from "../../../components/ada.select.builder";

export default function UpdateContract({
                                                  register,
                                                  setValue,
                                                  errors,
                                                  employee,
                                                  edit,dispatch, control
                                              }) {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [contractTypeList, setContractTypeList] = React.useState([]);
    const [activityTypeList, setActivityTypeList] = React.useState([]);
    const [paymentMethodList, setPaymentMethodList] = React.useState(
        paymentMethod
    );
    const [reasonEndContractList, setReasonEndContractList] = React.useState([]);
    const [deviseList, setDeviseList] = React.useState(devise);
    const [professionalStatusList, setProfessionalStatusList] = React.useState([]);
    const [workingTimeModalityList, setWorkingTimeModalityList] = React.useState(
        []
    );
    const [contract, setContract] = useState({});

    useEffect(() => {
        register({name: "contract.remuneration.currency"});
            console.log("employee.contract", employee.contract);
            setContract(employee.contract);
        setValue("contract.remuneration.currency", "EUR");

        dispatch({
            professionalStatus : employee.contract && employee.contract.professionalStatus ? employee.contract.professionalStatus : undefined,
            activityType : employee.contract && employee.contract.activityType ? employee.contract.professionalStatus : undefined,
            contractType : employee.contract && employee.contract.contractType ? employee.contract.contractType : undefined,
            workingTimeModality : employee.contract && employee.contract.workingTimeModality ? employee.contract.workingTimeModality : undefined,
        })



    }, []);

    const handlerChangeSalary = (value, name) => {
        switch (name) {
            case "contract.remuneration.annualSalary":
                setValue(
                    "contract.remuneration.monthlySalary",
                    Math.round((value / 12) * 100) / 100
                );
                setValue(
                    "contract.remuneration.dailySalary",
                    Math.round((value / 261) * 100) / 100
                );
                return;
            case "contract.remuneration.monthlySalary":
                setValue("contract.remuneration.annualSalary", Math.round(value * 12));
                setValue(
                    "contract.remuneration.dailySalary",
                    Math.round((value / 21) * 100) / 100
                );
                return;
            case "contract.remuneration.dailySalary":
                setValue("contract.remuneration.monthlySalary", Math.round(value * 21));
                setValue("contract.remuneration.annualSalary", Math.round(value * 261));
                return;
            default:
                setValue("contract.remuneration.monthlySalary", 0.0);
                setValue("contract.remuneration.annualSalary", 0.0);
                setValue("contract.remuneration.dailySalary", 0.0);
                return;
        }
    };

    const handleChangePaymentMethod = selectedOption => {
        console.log("contract.remuneration.paymentMethod", selectedOption);
        if (selectedOption !== null) {
            setValue("contract.remuneration.paymentMethod", selectedOption.value);
        } else {
            setValue("contract.remuneration.paymentMethod", undefined);
        }
    };


    const handleChangeReasonEndContract = selectedOption => {
        console.log("contract.reasonEndContract", selectedOption);
        if (selectedOption !== null) {
            setValue("contract.reasonEndContract", selectedOption.value);
        } else {
            setValue("contract.reasonEndContract", undefined);
        }
    };

    const handleChangeCurrency = selectedOption => {
        console.log("contract.remuneration.currency", selectedOption);
        if (selectedOption !== null) {
            setValue("contract.remuneration.currency", selectedOption.value);
        } else {
            setValue("contract.remuneration.currency", undefined);
        }
    };



    // const handleChangeManager = selectedOption => {
    //     console.log("manager", selectedOption);
    //     if (selectedOption !== null) {
    //         setValue("manager", selectedOption.value);
    //     } else {
    //         setValue("manager", undefined);
    //     }
    // };

    return (
        <div>
            <Label>
                <p style={{color: "red"}}> {errorMessage}</p>
            </Label>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.beginDate"/>
                        <AdaInputText
                            defaultValue={contract.beginDate}
                            type="date"
                            name="contract.beginDate"
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.contractType"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/contract_types/`}
                                          inputName={"contractType"}
                                          optionsType={"string"}
                                          defaultId={employee.contract.contractType}
                                          control={control}
                                          dispatch={dispatch}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.professionalStatus"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/professional_statuss/`}
                                          inputName={"professionalStatus"}
                                          optionsType={"string"}
                                          defaultId={employee.contract.professionalStatus}
                                          control={control}
                                          dispatch={dispatch}/>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.qualification"/>
                        <AdaInputText
                            name="contract.qualification"
                            defaultValue={contract.qualification}
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.coefficient"/>
                        <AdaInputText
                            name="contract.coefficient"
                            defaultValue={contract.coefficient}
                            type="number"
                            placeholder="0.00"
                            step="0.01"
                            errors={errors}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.workingTimeModality"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/working_time_modalitys/`}
                                          inputName={"workingTimeModality"}
                                          optionsType={"string"}
                                          defaultId={employee.contract.workingTimeModality}
                                          control={control}
                                          dispatch={dispatch}/>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="employee.activityType"/>

                        <AdaSelectBuilder errors={errors} register={register}
                                          setValue={setValue}
                                          uri={`${getHostByAppName(NOMENCLATURE_NAME)}/company_activity_areas/`}
                                          inputName={"activityType"}
                                          optionsType={"string"}
                                          defaultId={employee.contract.activityType}
                                          control={control}
                                          dispatch={dispatch}/>
                    </FormGroup>
                </Col>
            </Row>

            <fieldset>
                <legend>
                    <IntlMessages id="employee.trialPeriod"/>
                </legend>
                <hr style={{marginTop: 0}}/>
                <Row>
                    <Col>
                        <FormGroup>
                            <CustomInput
                                type="switch"
                                id="trialPeriodId"
                                name="contract.trialPeriod"
                                defaultChecked={
                                    contract !== undefined ? contract.trialPeriod : false
                                }
                                innerRef={register}
                                label={<IntlMessages id="employee.trialPeriod"/>}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <CustomInput
                                type="switch"
                                id="extendedTrialPeriodId"
                                name="contract.extendedTrialPeriod"
                                defaultChecked={
                                    contract !== undefined ? contract.extendedTrialPeriod : false
                                }
                                innerRef={register}
                                label={<IntlMessages id="employee.extendedTrialPeriod"/>}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </fieldset>

            <fieldset>
                <legend>
                    <IntlMessages id="employee.remuneration"/>
                </legend>
                <hr style={{marginTop: 0}}/>
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="employee.annualSalary"/>
                            <AdaInputText
                                type="number"
                                defaultValue={
                                    employee && employee.contract !== undefined
                                        ? employee.contract.remuneration !== undefined
                                        ? employee.contract.remuneration.annualSalary
                                        : 0
                                        : 0
                                }
                                placeholder="0.00"
                                step="0.01"
                                name="contract.remuneration.annualSalary"
                                errors={errors}
                                innerRef={register}
                                onChange={e =>
                                    handlerChangeSalary(
                                        e.target.value,
                                        "contract.remuneration.annualSalary"
                                    )
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="employee.monthlySalary"/>
                            <AdaInputText
                                type="number"
                                placeholder="0.00"
                                step="0.01"
                                name="contract.remuneration.monthlySalary"
                                defaultValue={
                                    employee && employee.contract !== undefined
                                        ? employee.contract.remuneration !== undefined
                                        ? employee.contract.remuneration.monthlySalary
                                        : 0
                                        : 0
                                }
                                errors={errors}
                                innerRef={register}
                                onChange={e =>
                                    handlerChangeSalary(
                                        e.target.value,
                                        "contract.remuneration.monthlySalary"
                                    )
                                }
                            />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <IntlMessages id="employee.dailySalary"/>
                            <AdaInputText
                                type="number"
                                placeholder="0.00"
                                step="0.01"
                                name="contract.remuneration.dailySalary"
                                defaultValue={
                                    employee && employee.contract !== undefined
                                        ? employee.contract.remuneration !== undefined
                                        ? employee.contract.remuneration.dailySalary
                                        : 0
                                        : 0
                                }
                                errors={errors}
                                innerRef={register}
                                onChange={e =>
                                    handlerChangeSalary(
                                        e.target.value,
                                        "contract.remuneration.dailySalary"
                                    )
                                }
                            />
                        </FormGroup>
                    </Col>

                    <Col className="col-sm-2">
                        <FormGroup>
                            <IntlMessages id="employee.currency"/>
                            <AdaSelect
                                name="contract.remuneration.currency"
                                errors={errors}
                                defaultValue={{
                                    key: 1,
                                    label: "EUR",
                                    value: "EUR"
                                }}
                                options={deviseList}
                                onChange={handleChangeCurrency}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="employee.transportationAllowance"/>
                            <AdaInputText
                                type="number"
                                placeholder="0.00"
                                step="0.01"
                                name="contract.remuneration.transportationAllowance"
                                defaultValue={
                                    employee && employee.contract !== undefined
                                        ? employee.contract.remuneration !== undefined
                                        ? employee.contract.remuneration.transportationAllowance
                                        : 0
                                        : 0
                                }
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="employee.mealAllowance"/>
                            <AdaInputText
                                type="number"
                                placeholder="0.00"
                                step="0.01"
                                name="contract.remuneration.mealAllowance"
                                defaultValue={
                                    employee && employee.contract !== undefined
                                        ? employee.contract.remuneration !== undefined
                                        ? employee.contract.remuneration.mealAllowance
                                        : 0
                                        : 0
                                }
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </fieldset>

            <fieldset>
                <legend>
                    <IntlMessages id="employee.end.contract"/>
                </legend>
                <hr style={{marginTop: 0}}/>
                <Row>
                    <Col className="col-sm-3">
                        <FormGroup>
                            <IntlMessages id="employee.endDate"/>
                            <AdaInputText
                                type="date"
                                name="contract.endDate"
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="employee.reasonEndContract"/>
                            <AdaSelect
                                name="contract.reasonEndContract"
                                errors={errors}
                                isClearable
                                options={reasonEndContractList}
                                onChange={handleChangeReasonEndContract}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </fieldset>
        </div>
    );
}
