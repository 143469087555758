import React, { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, Col, NavLink } from "reactstrap";
import classnames from "classnames";
import IntlMessages from "../../../helpers/intlMessages";
import DisplayInformation from "../../commun/displayInformation";
import EmployeeInformation from "./informations";
import EmployeeCivility from "./civility";
import EmployeeAddress from "./address";
import EmployeeBank from "./bank";
import EmployeeContract from "./contract";
import EmployeeContacts from "./contacts";
import {addressPostal, identity, person} from "../../../helpers/constants";
import LatestActivity from "../updateEmployee/UserInfoDoughnutWidget";
import WrapperInformation from "../updateEmployee/wrapper";
import * as service from "../../../service/crud.service";
import {EMPLOYEE_NAME, getHostByAppName} from "../../../service/host";
import EmployeeContactsUpdate from "../updateEmployee/employeeContactsUpdate";
import EmployeeSkills from "../updateEmployee/employee.skills";
import EmployeeDiplomas from "../updateEmployee/diplomas.employee";
import Documents from "../updateEmployee/employee.documents";
import DisplayInformationStyle from "../updateEmployee/displayInformationStyle";

export default function EmployeeDetails({ hideDetails, selectedElement }) {
  const [activeTab, setActiveTab] = useState("1");
  const [employee, setEmployee] = React.useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const [modalSuccessShow, setModalSuccessShow] = useState(false);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(
    () => {
      console.log("Hide employee details :", hideDetails);
      if (!hideDetails) {
        let idSelectedElement = "";
        if (selectedElement !== null && selectedElement !== undefined) {
          idSelectedElement = selectedElement.id;
          console.log("employee Id for details :", idSelectedElement);
          service.getById(getHostByAppName(EMPLOYEE_NAME),"employees", setEmployee, (error)=> console.log(error), idSelectedElement);
        }
      }
    },
    [hideDetails]
  );

  return !hideDetails && <div>
        <Nav tabs>
          <NavItem className="roe-card-body">
            <NavLink className={classnames({
                active: activeTab === "1"
              })} onClick={() => {
                toggle("1");
              }}>
              <IntlMessages id={`common.information`} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({
                active: activeTab === "2"
              })} onClick={() => {
                toggle("2");
              }}>
              <IntlMessages id={`employee.civilite`} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({
                active: activeTab === "3"
              })} onClick={() => {
                toggle("3");
              }}>
              <IntlMessages id={`employee.adresse`} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({
                active: activeTab === "4"
              })} onClick={() => {
                toggle("4");
              }}>
              <IntlMessages id={`common.cord.bancaires`} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({
                active: activeTab === "5"
              })} onClick={() => {
                toggle("5");
              }}>
              <IntlMessages id={`employee.contract`} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({
                active: activeTab === "6"
              })} onClick={() => {
                toggle("6");
              }}>
              <IntlMessages id={`common.contacts`} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({
              active: activeTab === "7"
            })} onClick={() => {
              toggle("7");
            }}>
              <IntlMessages id={`employee.skills`} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({
              active: activeTab === "8"
            })} onClick={() => {
              toggle("8");
            }}>
              <IntlMessages id={`employee.diplomas`} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({
              active: activeTab === "9"
            })} onClick={() => {
              toggle("9");
            }}>
              <IntlMessages id={`employee.documents`} />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <LatestActivity
                id={employee.user}
                icon={person}
                title={employee.civility+". "+employee.firstName+" "+employee.lastName}
                code={employee.code}
                email={employee.contactInformation && employee.contactInformation.email ? employee.contactInformation.email : "ND"}
                telephone={employee.contactInformation !== undefined ? employee.contactInformation.mobile : "ND"}
                statut={employee.enable === true ? "Activé" : "Désactivé"}
                categ={employee.category}
                func={employee.function}
                organ={employee.organization}
                mut={employee.otherDataEmployeeType && employee.otherDataEmployeeType.mutual ? employee.otherDataEmployeeType.mutual : null}
                experience={employee.skillEmployeeType && employee.skillEmployeeType.experienceLevel ? employee.skillEmployeeType.experienceLevel : null}
                secnum={employee.socialSecurity && employee.socialSecurity.socialSecurityNumber ? employee.socialSecurity.socialSecurityNumber :"ND"}
                dateaff={employee.socialSecurity && employee.socialSecurity.membershipDate ? employee.socialSecurity.membershipDate :"ND"}
                recrutement={employee.recrutementOfficer ? employee.recrutementOfficer.firstName+" "+employee.recrutementOfficer.lastName :"ND"}
                learning={employee.skillEmployeeType && employee.skillEmployeeType.learningType ? employee.skillEmployeeType.learningType : "ND" } 
            />
            <DisplayInformationStyle icon={""}
                                     title={""}
                                     inf1={{
                                       title: "Date visite médicale",
                                       value: employee.otherDataEmployeeType && employee.otherDataEmployeeType.medicalExaminationDate ? employee.otherDataEmployeeType.medicalExaminationDate : "ND"
                                     }}
                                     inf2={{
                                       title: "Véhicule",
                                       value: employee.otherDataEmployeeType && employee.otherDataEmployeeType.vehicle ? "Oui" : "Non"
                                     }}
                                     inf3={{
                                       title: "Mobilité ville",
                                       value: employee.otherDataEmployeeType && employee.otherDataEmployeeType.cityMobility ? employee.otherDataEmployeeType.cityMobility : "N.D"
                                     }}
                                     inf5={{
                                       title: "Disponibilité",
                                       value: employee.otherDataEmployeeType && employee.otherDataEmployeeType.available ? "Oui" : "Non"
                                     }}
                                     inf6={{
                                       title: "Interne",
                                       value: employee.otherDataEmployeeType && employee.otherDataEmployeeType.internal ? "Oui" : "Non"
                                     }}
                                     inf4={{
                                       title: "Années d'expérience",
                                       value: employee.skillEmployeeType && employee.skillEmployeeType.experienceLevel ? employee.skillEmployeeType.experienceLevel : "N.D"
                                     }}
            />
          </TabPane>
          <TabPane tabId="2">
            <Col sm="15">
              <EmployeeCivility employee={employee} />
            </Col>
          </TabPane>
          <TabPane tabId="3">
            <Col sm="15">
              <WrapperInformation title={"Adresse postal"}
                                  icon={addressPostal}
                                  informationLigne1={employee.address ? (employee.address.number + " " + employee.address.street):"ND"}
                                  informationLigne2={employee.address ? (employee.address.zipCode + " " + employee.address.city + " " +employee.address.country):"ND"}/>
            </Col>
          </TabPane>
          <TabPane tabId="4">
            <Col sm="15">
              <EmployeeBank employee={employee} />
            </Col>
          </TabPane>
          <TabPane tabId="5">
            <Col sm="15">
              <EmployeeContract employee={employee} />
            </Col>
          </TabPane>
          <TabPane tabId="6">
            <Col sm="15">
              <EmployeeContactsUpdate employee={employee} edit={false} />
            </Col>
          </TabPane>
          <TabPane tabId="7">
            <Col sm="15">
              <EmployeeSkills employee={employee} edit={false}/>
            </Col>
          </TabPane>
          <TabPane tabId="8">
            <Col sm="15">
              <EmployeeDiplomas employee={employee} edit={false}/>
            </Col>
          </TabPane>
          <TabPane tabId="9">
            <Col sm="15">
              <Documents objectResource={employee} setObjectResource={setEmployee}
                         context={"employees"} edit={false}/>
            </Col>
          </TabPane>
        </TabContent>
      </div>;
}
