import React, {useEffect, useState} from "react";
import {AdaSelect} from "@adaming/ada-react-component";
import {Controller} from "react-hook-form";
import * as service from "../service/crud.service";

export default function AdaSelectBuilder({register, errors, setValue, uri, inputName, options, optionsType, defaultId, control, dispatch}) {

    const [items, setItems] = useState([]);

    useEffect(() => {
        if (options === undefined) {
            async function getItems() {
                service.getUri(uri).then(result=>initListAndDefaultValue(result.data))
            }
            getItems();
        } else {
            initListAndDefaultValue(options);
        }
    }, [defaultId,uri]);


    const initListAndDefaultValue = (options) => {
        let defaultValue = null;
        let list = [];
        if (options !== undefined && Array.isArray(options)) {
            options.forEach(item => {
                list.push({key: item.id, label: item.name, value: item.id})
                if (defaultId !== undefined && defaultId !== null && item.id === defaultId) {
                    defaultValue = {key: item.id, label: item.name, value: item.id};
                    //dispatch(initValue(item.id))
                }
            })
        }
        setValue(inputName, defaultValue);
        setItems(list);

    }


    const handleChangeItem = selectedOption => {

        var selectedValue = {};
        if (selectedOption[0] !== null && selectedOption[0].key !== null) {
            selectedValue = initValue(selectedOption[0].key)
        } else {
            selectedValue[`${inputName}`] = null;
        }
        dispatch(selectedValue);
    };

    const initValue = (selectedOption) => {
        var selectedValue = {};
        if (optionsType === 'object') {
            selectedValue[`${inputName}`] = {"id": selectedOption};
        } else if (optionsType === 'string') {
            selectedValue[`${inputName}`] = selectedOption;
        }
        return selectedValue
    }

    return (<div>
        <Controller
            as={<AdaSelect name={`${inputName}`}/>}
            name={`${inputName}`}
            errors={errors}
            options={items}
            onChange={handleChangeItem}
            innerRef={register}
            isClearable
            control={control}
        />
    </div>);
}